import React from 'react'
import Base from './base'
import Footer from '../components/footer'
import NavBar from '../components/nav-bar'
import TopBanner from '../components/top-banner'

const Layout = ({ className = '', children }) => (
  <Base>
    <TopBanner />

    <div className='relative'>
      <NavBar path={children.props.path} />
      <div className={className}>
        {children}
      </div>
      <Footer />
    </div>
  </Base>
)

export default Layout
