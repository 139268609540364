import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'
import clientConfig from '../../client-config'
import serializers from './serializers'

const BlockContent = ({ blocks, className }) => (
  <div className={`block-content ${className ? className : ''}`}>
    <BaseBlockContent blocks={blocks} serializers={serializers} {...clientConfig.sanity} />
  </div>
)

export default BlockContent
