import React from 'react'
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'

export default ({ node, ...props }) => {
  const { url } = node
  const id = getYouTubeId(url)
  return (
    <div className='w-full mb-8 aspect-ratio-16/9 relative'>
      <YouTube videoId={id} className='absolute top-0 w-full h-full' />
    </div>
  )
}
