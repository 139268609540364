import React from 'react'
import PlainLayout from './plain'

export default ({ children, pageContext }) => {
  switch (pageContext.type) {
    default:
      return (
        <PlainLayout>{children}</PlainLayout>
      )
  }
}
