import React from 'react'
import { useNavigate } from '@reach/router'

let dataLayer = [];

if (typeof window !== 'undefined') {
  dataLayer = window.dataLayer = window.dataLayer || dataLayer
}

export const TrackedLink = props => {
  const navigate = useNavigate()

  const eventCallback = props.eventCallback
    ? props.eventCallback
    : _ => navigate(props.to)

  const pushData = (e) => {
    eventCallback && e.preventDefault()

    dataLayer.push({
      ...props.dataLayer,
      'eventCallback': eventCallback
    })
  }

  return (
    <a className={props.className} href={props.to} onClick={pushData}>
      {props.children}
    </a>
  )
}

export const ProductImpressions = props => {
  const products = props.products

  const impressions = products.map((product, index) => ({
    name: product.node.title,
    id: product.node.shopifyId || product.node.id,
    category: product.node.category.title || '',
    price: product.node.defaultPrice,
    position: index + 1
  }))

  const data = {
    ecommerce: {
      // 'currencyCode': 'NOK',
      impressions: [
        ...impressions
      ]
    }
  }

  impressions && dataLayer.push(data)

  return (
    <>{props.children}</>
  )
}