import React from 'react'

export default ({ text }) => {
  if (typeof text !== 'string') {
    return text
  }

  // Hyphenation for dummies
  text = text.replace('vaksine', '&shy;vaksine')
  text = text.replace('forandringer', '&shy;forandringer')
  text = text.replace('Menstruasjons', 'Menstruasjons&shy;')
  text = text.replace('Dine valg', 'Dine&nbsp;valg')

  return (
    <span dangerouslySetInnerHTML={{__html: text}}></span>
  )
}
