import React from 'react'
import { Link } from 'gatsby'

/**
 * Used to render either a Link or a regular <a> depending on whether or not
 * the URL is internal or not.
 *
 * For relation based links from Sanity, see internalLink.js
 */
const InternalOrExternalLink = ({ children, ...props }) => {
  const href = props.to || props.href
  const internal = /^\/(?!\/)/.test(href)

  if (internal) {
    props.to = href
    delete props.href
    return (<Link {...props}>{children}</Link>)
  }

  props.href = href
  delete props.to

  return (<a {...props}>{children}</a>)
}

export default InternalOrExternalLink
