import React from 'react'
import Figure from './figure'
import Youtube from './youtube'
import InternalLink from './internalLink'
import inlineImage from './inlineImage'
import InternalOrExternalLink from './internalOrExternalLink'
import ProductCards from './productCards'

const serializers = {
  types: {
    figure: (props) => {
      if (props.node._type === 'figure') {
          return (<Figure node={props.node} />)
      }
    },
    youtube: Youtube,
    productCards: ProductCards
  },
  marks: {
    link: ({ mark, children }) => {
      const { blank, href } = mark
      return (
        <InternalOrExternalLink target={blank ? '_blank' : '_self'} to={href}>
          {children}
        </InternalOrExternalLink>
      )
    },
    internalLink: ({ mark, children }) => <InternalLink reference={mark.reference}>{children}</InternalLink>,
    inlineImage
  }
}

export default serializers
