import React from 'react'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import clientConfig from '../../client-config'

export default (props) => {
  const asset = props.mark && props.mark.asset
  const image = getFluidGatsbyImage(asset, { maxWidth: 180 }, clientConfig.sanity)

  return (
    <>
      {image ? (
        <>
          <img className={props.mark.position} src={image.src} />
          {props.children}
        </>
      ) : (
        <>
          {props.children}
        </>
      )}
    </>
  )
}