import React from 'react'
import { Link } from 'gatsby'

export default ({ reference, children, ...props }) => {
  if (!reference) {
    return (<>{children}</>)
  }

  const { slug, category } = reference
  const href = category ? `/${category.slug.current}/${slug.current}` : `/${slug.current}`

  return (
    <Link to={href} {...props}>{children}</Link>
  )
}
