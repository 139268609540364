import React from 'react'
import Helmet from 'react-helmet'

import '../css/style.css'

const Layout = ({ children }) => (
  <>
    <Helmet>
      <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="eee1e1a4-cfb4-4bda-b8c8-bbe3a6d4c425" type="text/javascript" async></script>
    </Helmet>
    {children}
  </>
)

export default Layout
