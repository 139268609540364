import React from 'react'
import ProductCard from './product-card'

export default ({ ...props }) => {
  const products = {...props.node.products}
  let heading = {...props.node.heading}

  if (heading) {
    heading = Object.values(heading).join('')
  }

  return (
    <div className="my-20">
      <section id='produkter' className='px-4 py-20 lg:py-32 text-center relative'>
        <div className='max-w-screen-lg mx-auto'>

          {heading && (
            <h2 className='text-black display mb-12'>{heading}</h2>
          )}

          <div className='flex flex-wrap justify-center mb-10'>
            {Object.keys(products).map((key) => (
              <div className='w-full md:w-1/2 flex mb-4 md:mb-8' key={products[key].id}>
                <ProductCard className='h-full w-full md:mx-4 bg-primary-background-beige' product={products[key]} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}
