import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"

export default connectSearchBox(
  ({ refine, currentRefinement, className, size, onFocus }) => (
    <form className={className} action="/search" method="GET" autoComplete="off">
      <input
        className={size === 'large' ? 'inline-block p-4 lg:px-8 text-lg rounded-full w-full outline-none placeholder-black' : 'inline-block p-2 lg:p-3 px-3 lg:px-6 rounded-full w-screen max-w-xs sm:max-w-xs outline-none placeholder-black border border-primary-background-beige-dark'}
        type="search"
        placeholder="Søk på tjenester og artikler..."
        aria-label="Search"
        name="query"
        onChange={e => refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
      />
    </form>
  )
)