import { createRef, default as React, useState, useMemo, useEffect } from "react"
import { Link } from 'gatsby'
import { globalHistory } from '@reach/router'
import algoliasearch from "algoliasearch/lite"
import { 
  InstantSearch,
  Hits,
  Configure
} from "react-instantsearch-dom"
import { format } from 'date-fns'
import nb from 'date-fns/locale/nb'
import SearchBox from "./search-box"
import StateResults from "./search-state-results"
import styles from "./search-inline.module.css"
import useClickOutside from "./use-click-outside"

const Hit = (props) => {
  return (
    <Link to={props.hit.href} className="px-3 py-3 lg:px-6 lg:py-4 hover:bg-primary-background no-underline block mb-2">
      <div className="font-semibold text-lg md:text-2xl">
        <span>{props.hit.title}</span>
      </div>
      <div className="text-xs mb-1 md:mb-3">
        <span className="inline-block">{props.hit.type}</span>
        {props.hit.createdAt && props.hit.type == 'Bloggartikkel' && (
          <span className="inline-block"><span className="inline-block mx-1">&bull;</span>Skrevet {format(new Date(props.hit.createdAt), 'Do MMMM YYYY', { locale: nb })}</span>
        )}
      </div>
      
      <div className="hit-description text-sm">
        {props.hit.body && props.hit.body.length > 150 ? props.hit.body.substring(0, 150) + '...' : props.hit.body }
      </div>

      {props.hit.categoryTitle && (<span className="mt-2 uppercase tracking-wide label text-red bg-primary-light inline-block rounded-sm py-1 px-2 text-xs">
        {props.hit.categoryTitle}
      </span>)}
    </Link>
  );
}

export default function Search() {
  const rootRef = createRef()
  const [query, setQuery] = useState()
  const [hasFocus, setFocus] = useState(false)
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )
  
  useClickOutside(rootRef, () => setFocus(false))

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setFocus(false)
    })
  }, [setFocus])

  return (
    <div ref={rootRef} className="ml-auto relative">
      <InstantSearch
        searchClient={searchClient}
        indexName="content"
        onSearchStateChange={({ query }) => {
          setQuery(query)
        }}
      >
        <Configure
          hitsPerPage={5}
         />
        <SearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} />
        <div className={`${styles.SearchResult} absolute right-0 top-full z-10 p-3 lg:px-6 py-6 lg:py-8 rounded-md mt-2 overflow-y-scroll bg-white ${hasFocus ? 'block' : 'hidden'}`}>
          <div className="text-center uppercase font-bold text-xs lg:text-sm text-primary-dark mb-3 lg:mb-6">Søketreff</div>
          <Hits hitComponent={Hit} />
          <StateResults showMore={true} />
        </div>
      </InstantSearch>
    </div>
  )
}